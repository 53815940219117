import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import JobsComponent from "../components/JobsComponent"
import Logo from "../components/Logo"
import { graphql } from "gatsby"

export default function NotFoundPage({ data }) {
  return (
    <Container>
      <Helmet>
        <title>404 Job Not Found</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Row className="top-row-margin row-margins">
        <Col>
          <a href="https://iq-inc.com/" title="IQ Inc">
            <Logo />
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="job-openings-heading">
            Uh oh! We don't recognize that job!
          </div>
        </Col>
      </Row>
      <Row className="row-margins">
        <Col md={{ span: 8, offset: 2 }}>
          <div className="blurb-summary">
            Maybe one of these other jobs are of interest to you?
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="gray-line-separator">
          <div className="job-details-gray-line"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <JobsComponent jobs={data.iqjobads.jobAds} />
        </Col>
      </Row>
    </Container>
  )
}
export const query = graphql`
  {
    iqjobads {
      jobAds {
        title
        slug
        summary
      }
    }
  }
`
