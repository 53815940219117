import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Jobs.scss"
import { JobItemComponent } from "./JobItemComponent"
import { Row } from "react-bootstrap"

export default function JobsComponent(props) {
  return (
    <Row className="jobs-component">
      {props.jobs.map(job => (
        <JobItemComponent job={job} key={job.title} />
      ))}
    </Row>
  )
}
