import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Jobs.scss';
import { Col, Card } from 'react-bootstrap';
import { Link } from 'gatsby'

export class JobItemComponent extends React.Component {
    render() {
        return (
            <Col md={4} className="job-item-column">
                <Link className="job-item-link" to={'/jobs/' + this.props.job.slug + '/'}>
                    <Card className="job-item">
                        <Card.Body>
                            <div className="job-item-title">{this.props.job.title}</div>
                            <div className="job-item-summary">{this.props.job.summary}</div>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
        );
    }
}
